import React, { useState } from "react";
import {
  IconDownArrow,
  IconRightArrow,
  IconPencil,
  modalInstance,
  IconDelete,
  WarningConfirmationModal,
  IconClone,
  Input,
  C8,
  Text,
} from "@fundrecs/ui-library";
import { observer } from "mobx-react-lite";
import { MODAL_IDS, WORKFLOW_STAGES, WORKFLOW_STATUS } from "utils/workflows/enums";
import { useStore } from "store/Store";
import { v4 as uuidv4 } from "uuid";
import styles from "./InputSection.module.scss";
import { InputSectionSummaryView } from "./InputSectionSummaryView";

const InputSection = observer(({ children, importRule, workflow, editingStage }) => {
  const [summaryView, setSummaryView] = useState(false);

  const { importRuleName, importRuleUuid, saved = true, inclusionRules } = importRule;

  const templateImportRules = workflow.getCurrentWorkflowTemplates();

  const { workflowsStore } = useStore();

  const getImportRuleIndex = () => {
    return templateImportRules.findIndex((importRule) => importRule.importRuleUuid === importRuleUuid);
  };

  const [editable, setEditable] = useState(() => {
    return (
      // are there any inclusionRules saved yet?
      inclusionRules?.length === 0 &&
      // are we in the input stage?
      editingStage === WORKFLOW_STAGES.INPUT &&
      // is this a new draft workflow?
      workflow.status === WORKFLOW_STATUS.DRAFT
    );
  });

  const toggleSummaryView = (value) => {
    setSummaryView(value);
  };

  const deleteIcon = () => {
    if (workflow.getCurrentWorkflowTemplates().length === 1) {
      return styles.disabledDeleteCommand;
    } else {
      return styles.deleteCommand;
    }
  };

  const cloneOutput = async () => {
    const clonedImportRule = { ...templateImportRules[getImportRuleIndex()] };
    clonedImportRule.importRuleName = templateImportRules[getImportRuleIndex()]["importRuleName"].concat(" (copy)");
    clonedImportRule.importRuleUuid = uuidv4();
    workflowsStore.addClonedTemplateImportRuleToWorkflow(clonedImportRule);

    const response = await workflowsStore.saveWorkflowClonedInput();
    const { success } = response;
    if (success) {
      templateImportRules.push(clonedImportRule);
    } else {
      workflowsStore.removeLastTemplateImportRuleFromWorkflow();
      templateImportRules.pop();
    }
  };

  const deleteInput = async () => {
    const templateImportRules = workflow.getCurrentWorkflowTemplates();
    //Remove any inputs which have no templateUuid's assigned or haven't been saved, No BE request needed
    if (saved === false) {
      templateImportRules.splice(
        templateImportRules.findIndex((importRule) => importRule.importRuleUuid === importRuleUuid),
        1
      );
    } else {
      const { success } = await workflowsStore.deleteWorkflowInput(importRuleUuid);

      if (success) {
        templateImportRules.splice(
          templateImportRules.findIndex((importRule) => importRule.importRuleUuid === importRuleUuid),
          1
        );
      }
    }
  };

  const toggleRemoveReportOutputModal = () => {
    modalInstance(MODAL_IDS.CONFIRM_DELETE_TEMPLATE_IMPORT_RULE.concat(getImportRuleIndex())).toggle();
  };

  const updateImportRuleName = (event) => {
    const {
      target: { value },
    } = event;

    workflow.updateTemplateImportRuleName(value, getImportRuleIndex());
  };

  const checkIfEditViewAndLastTemplateImportRule = () => {
    if (workflowsStore.getReadOnlyWorkflow() !== null) {
      if (editingStage !== WORKFLOW_STAGES.INPUT) {
        let importRuleIndex = getImportRuleIndex();
        const templateImportRulesLength = workflowsStore.getReadOnlyWorkflowTemplates().length;

        return importRuleIndex + 1 === templateImportRulesLength ? "" : styles.inputSectionBottomBorder;
      } else {
        return styles.inputSectionBottomBorder;
      }
    }
  };

  return (
    <>
      <WarningConfirmationModal
        modalId={MODAL_IDS.CONFIRM_DELETE_TEMPLATE_IMPORT_RULE.concat(getImportRuleIndex())}
        heading={`"Are you sure you want to delete input '${importRuleName}'?"`}
        text="This action will affect your workflow and any templates and output mappings assigned to this input. This action cannot be undone."
        cancel="Cancel"
        confirm="Delete input"
        onConfirm={() => {
          deleteInput();
        }}
      />
      <div
        className={[
          `${
            getImportRuleIndex() === 0 && editingStage === WORKFLOW_STAGES.INPUT
              ? styles.inputSectionTopBorder
              : `${checkIfEditViewAndLastTemplateImportRule()}`
          }`,
        ].join(" ")}
      >
        <div className={["d-flex align-items-center", `${!summaryView ? "pt-12 pb-12" : "pt-12"}`].join(" ")}>
          {editable || summaryView ? (
            <IconDownArrow
              className={[`${styles.iconDownArrow}`, `${editable && styles.iconDownArrowDisabled}`].join(" ")}
              onClick={() => {
                toggleSummaryView(!summaryView);
              }}
            />
          ) : (
            <IconRightArrow
              className={styles.iconRightArrow}
              onClick={() => {
                toggleSummaryView(!summaryView);
              }}
            />
          )}
          <div className={[`${styles.iconSpaceLeft}`, "flex-grow-1"].join(" ")}>
            <C8>
              {editable && (
                <Input
                  onChange={(event) => {
                    updateImportRuleName(event);
                  }}
                  placeholderText={importRuleName}
                  size="md"
                  value={importRuleName}
                />
              )}

              {!editable && (
                <Text variant="secondary" size="sm" weight="medium">
                  {importRuleName === null ? "Output_".concat(getImportRuleIndex() + 1) : importRuleName}
                </Text>
              )}
            </C8>
          </div>
          <div className="d-flex ">
            {editingStage === WORKFLOW_STAGES.INPUT && (
              <>
                <IconPencil
                  className={[`${styles.editCommand}`, `${editable && styles.disabledEditCommand}`].join(" ")}
                  onClick={() => {
                    setEditable(true);
                    workflowsStore.addEditableInput(importRuleUuid);
                  }}
                />
                <IconClone
                  className={[`${styles.cloneCommand}`, `${editable && styles.disabledCloneCommand}`].join(" ")}
                  onClick={() => {
                    cloneOutput();
                  }}
                />
                <IconDelete
                  className={[`${deleteIcon()}`].join(" ")}
                  onClick={() => {
                    toggleRemoveReportOutputModal();
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className={[`${summaryView && "pb-12"}`].join(" ")}>
          {summaryView !== false && !editable && <InputSectionSummaryView importRule={importRule} workflow={workflow} />}
        </div>
        <div>{editable && React.cloneElement(children, { editable: editable, setEditable: setEditable })}</div>
      </div>
    </>
  );
});

export { InputSection };
