import { useEffect, useState } from "react";
import { workflowsStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";

const useSourceWorkflowsData = (workflowUuid) => {
  const teamId = useTeamId();

  const [sourceWorkflows, setSourceWorkflows] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { data, success } = await workflowsStore.loadSourceWorkflows(teamId, workflowUuid);
      if (success) {
        setSourceWorkflows(data);
      }
    };

    fetchData();
  }, []);

  return sourceWorkflows;
};

export { useSourceWorkflowsData };
