import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS, API_REQUEST_TIMEOUT_DOWNLOAD_FILE_MS } from "../config";

const workflows = {
  // WORKFLOWS
  createWorkflow: (teamId, workflow) => fusionRequest.post(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOWS}`, workflow),
  getWorkflows: (teamId) => fusionRequest.get(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOWS}`),
  getSourceWorkflows: (teamId, workflowUuid) => fusionRequest.get(`teams/${teamId}/workflows/${workflowUuid}/sources`),
  getDestinationWorkflows: (teamId, workflowUuid) => fusionRequest.get(`teams/${teamId}/workflows/${workflowUuid}/destinations`),
  getWorkflowByUuid: (teamId, workflowUuid) => fusionRequest.get(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOWS}/${workflowUuid}`),
  updateWorkflow: (teamId, workflowUuid, update) => fusionRequest.patch(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOWS}/${workflowUuid}`, update),
  publishWorkflow: (teamId, workflowUuid) => fusionRequest.post(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOWS}/${workflowUuid}/operation/publish`),
  deleteWorkflow: (teamId, workflowUuid) => fusionRequest.delete(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOWS}/${workflowUuid}`),
  triggerWorkflow: (teamId, workflowUuid, formData) =>
    fusionRequest.post(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOWS}/${workflowUuid}/trigger`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }),

  // WORKFLOW RUN RESULTS
  getRunResults: (teamId, fromTimestamp, toTimestamp) =>
    fusionRequest.get(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOW_RUN_RESULTS}?fromTimestamp=${fromTimestamp}&toTimestamp=${toTimestamp}`),
  getRunResultsByWorkflowUuid: (teamId, workflowUuid, fromTimestamp, toTimestamp) =>
    fusionRequest.get(
      `teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOW_RUN_RESULTS}/workflow/${workflowUuid}/all?fromTimestamp=${fromTimestamp}&toTimestamp=${toTimestamp}`
    ),
  getRunResultFile: (fileUuid) =>
    fusionRequest.get(`download/${fileUuid}`, "", {
      headers: { "Content-Type": "blob" },
      responseType: "arraybuffer",
      timeout: API_REQUEST_TIMEOUT_DOWNLOAD_FILE_MS,
    }),
  requeueFilesForWorkflowRun: (teamId, workflowRunUuid) => {
    return fusionRequest.post(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOW_RUN_RESULTS}/${workflowRunUuid}/requeue-files`);
  },
  rerunWorkflowFromRun: (teamId, workflowRunUuid) => {
    return fusionRequest.post(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOW_RUN_RESULTS}/${workflowRunUuid}/rerun`);
  },

  //WORKFLOW PENDING FILES QUEUE
  getPendingFiles: (teamId, workflowUuid) => fusionRequest.get(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOW_QUEUE}/workflow/${workflowUuid}`),
  triggerWorkflowForPendingFiles: (teamId, workflowUuid, workflowQueuedFileIds) =>
    fusionRequest.post(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOW_QUEUE}/workflow/${workflowUuid}/trigger`, workflowQueuedFileIds),

  removeFromPendingFileQueue: (teamId, workflowUuid, workflowQueuedFileIds) =>
    fusionRequest.post(`teams/${teamId}/${API_ENDPOINTS.WORKFLOWS.WORKFLOW_QUEUE}/workflow/${workflowUuid}/remove`, workflowQueuedFileIds),
};

export { workflows };
