import { useEffect, useState } from "react";
import { Text, IconAlertMessage } from "@fundrecs/ui-library";
import { useImportEmailConnectionData } from "components/workflows/hooks/useImportEmailConnectionData";
import { useImportSftpConnectionData } from "components/workflows/hooks/useImportSftpConnectionData";
import { INPUT_CONFIG_TYPES } from "utils/workflows/enums";
import { getImportConnectionName, getInputMappingPath, getInputMappingName, getImportConnectionEmailAddress } from "components/workflows/reusable";
import { useStore } from "store/Store";
import { BE_SOURCE_TYPES, FE_SOURCE_TYPES } from "utils/enums";
import { generateText } from "./reusable/GenerateText";
import styles from "./InputSummaryView.module.scss";
import { SummarySetting, SummarySettingsList } from "../../SettingsList/SummarySettingsList";
import { useTeamId } from "store/hooks/useTeamId";

const renderRuleType = (criteria, type) => {
  if (criteria) {
    return (
      <Text element="div" size="xs" variant="tertiary" weight="regular" align="left">
        {generateText(criteria, type)}
      </Text>
    );
  } else {
    return (
      <Text element="div" size="xs" variant="tertiary" weight="regular" align="left">
        Apply this template to all incoming data
      </Text>
    );
  }
};

// eslint-disable-next-line react/no-multi-comp
const SummarySection = ({ uuid, fieldValues, builder = true }) => {
  const { templatesStore } = useStore();
  let section = {
    templateName: "",
    rules: [],
    index: 0,
  };

  const isTemplateValid = (templateUuid) => {
    const templates = templatesStore.getTemplatesLinkedToOutputMapping();
    return templates.some((template) => template.templateUuid === templateUuid);
  };

  fieldValues.templates.map(({ templateUuid, criteria = false, type }, index) => {
    if (uuid === templateUuid) {
      const { name = "" } = templatesStore.getTemplates().filter((template) => template.templateUuid === templateUuid)[0] || {};
      section.templateName = name;
      section.rules.push({ index, criteria, type });
      section.index = index;
      section.error = !isTemplateValid(templateUuid);
    }
  });
  return (
    <>
      <div className={styles.item}>
        <span className={[`${styles.labels}`, "mr-4"].join(" ")}>
          <Text element="dt" size="sm" variant="tertiary" weight="regular">
            Template assigned :
          </Text>
        </span>
        <div className="d-flex flex-row">
          <Text element="dd" size="sm" variant="secondary">
            {section.templateName}
          </Text>
          <div>
            {section.error && (
              <span className={["pr-0", `${builder && "text-right"}`, `${!builder && "pl-0"}`].join(" ")}>
                <Text variant="error" weight="regular" size="sm">
                  <IconAlertMessage />
                  Template must be mapped to output mapping belonging to output(s) in step 1
                </Text>
              </span>
            )}
          </div>
        </div>
        <div />
      </div>

      <div className={styles.leftIndent}>
        {section.rules.map(({ criteria, type }, index) => {
          return (
            <div
              className={["light-text-secondary text-medium text-sm pr-0 pt-4 pl-4", `${builder && "text-right"}`, `${!builder && "pl-0"}`].join(" ")}
              key={index}
            >
              {renderRuleType(criteria, type)}
            </div>
          );
        })}
      </div>
    </>
  );
};

const InputSectionSummaryView = ({ importRule, workflow }) => {
  const {
    inclusionRules = [],
    source: { type, workflowUuid = null },
  } = importRule;
  const { templatesStore, workflowsStore } = useStore();
  const fieldValues = { templates: inclusionRules };

  const importSftpConnectionData = useImportSftpConnectionData();
  const importEmailConnectionData = useImportEmailConnectionData();
  const teamId = useTeamId();

  const [render, doRender] = useState(false);
  const outputMappingUuids = workflowsStore.getReadOnlyWorkflowReportsOutputMappingUuids();

  const {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: { templateImportRules },
        },
      },
    },
  } = workflow;

  const loadTemplatesLinkedToOutputMappings = async () => {
    const { success } = await templatesStore.loadTemplatesLinkedToOutputMappings(teamId, outputMappingUuids);
    doRender(success);
  };
  const loadTemplates = async () => {
    await templatesStore.loadTemplates(teamId);
    await loadTemplatesLinkedToOutputMappings();
  };

  useEffect(() => {
    if (teamId) {
      outputMappingUuids.length > 0 ? loadTemplates() : doRender(true);
    }
  }, [teamId]);

  const checkType = (type) => {
    if (type === BE_SOURCE_TYPES.FUSION_UI) {
      //We need this because what we display on the UI doesn't match the type that comes from the API
      return FE_SOURCE_TYPES.FUSION_UI;
    } else if (type === BE_SOURCE_TYPES.WORKFLOW) {
      return FE_SOURCE_TYPES.WORKFLOW;
    } else {
      return type;
    }
  };

  const getSourceName = (type) => {
    if (type === INPUT_CONFIG_TYPES.SFTP.KEY) {
      const {
        source: { connectionUuid, inputMappingId },
      } = importRule;

      return (
        <>
          <SummarySetting name="Source type" value={"SFTP directory"} />
          {importSftpConnectionData.length > 0 && inputMappingId !== null ? (
            <>
              <SummarySetting name="SFTP directory name" value={getInputMappingName(importSftpConnectionData, connectionUuid, inputMappingId)} />
              <SummarySetting name="SFTP directory path" value={getInputMappingPath(importSftpConnectionData, connectionUuid, inputMappingId)} />
              <SummarySetting name="SFTP connection" value={getImportConnectionName(importSftpConnectionData, connectionUuid)} />
            </>
          ) : (
            <></>
          )}
        </>
      );
    }

    if (type === INPUT_CONFIG_TYPES.EMAIL.KEY) {
      const {
        source: { connectionUuid },
      } = importRule;
      return (
        <>
          <SummarySetting name="Source type" value="Email address" />
          <SummarySetting name="Email address name" value={getImportConnectionName(importEmailConnectionData, connectionUuid)} />
          <SummarySetting name="Email address" value={getImportConnectionEmailAddress(importEmailConnectionData, connectionUuid)} />
        </>
      );
    }

    if (type === FE_SOURCE_TYPES.FUSION_UI || type === BE_SOURCE_TYPES.FUSION_UI) {
      return <SummarySetting name="Source type" value={FE_SOURCE_TYPES.FUSION_UI} />;
    }

    if (type === FE_SOURCE_TYPES.WORKFLOW || type === BE_SOURCE_TYPES.WORKFLOW) {
      return (
        <>
          <SummarySetting name="Source type" value={FE_SOURCE_TYPES.WORKFLOW} />
          <SummarySetting name="Workflow" value={workflowsStore.getWorkflowName(workflowUuid)} />
        </>
      );
    }

    // Default just return type e.g. DTCC
    return <SummarySetting name="Source type" value={type} />;
  };

  const inputSourceType = templateImportRules.length > 0 ? checkType(type) : FE_SOURCE_TYPES.FUSION_UI;

  return (
    render && (
      <SummarySettingsList>
        {getSourceName(inputSourceType)}
        <div>
          <>
            {[...new Set(fieldValues.templates.map((template) => template.templateUuid))].map((uuid, index) => {
              return <SummarySection uuid={uuid} fieldValues={fieldValues} />;
            })}
          </>
        </div>
      </SummarySettingsList>
    )
  );
};

export { InputSectionSummaryView, SummarySection, renderRuleType };
