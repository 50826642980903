import React, { useState } from "react";
import {
  IconDownArrow,
  IconRightArrow,
  IconPencil,
  modalInstance,
  IconDelete,
  WarningConfirmationModal,
  IconClone,
  Input,
  C8,
  Text,
} from "@fundrecs/ui-library";
import { observer } from "mobx-react-lite";
import { OutputSectionSummaryView } from "./OutputSectionSummaryView";
import { MODAL_IDS, WORKFLOW_STAGES, WORKFLOW_STATUS } from "utils/workflows/enums";
import { useStore } from "store/Store";
import { v4 as uuidv4 } from "uuid";
import styles from "./OutputSection.module.scss";

const OutputSection = observer(({ children, report, workflow, editingStage }) => {
  const [summaryView, setSummaryView] = useState(false);

  const { outputMappingUuid, reportName, reportUuid, saved = true } = report;

  const reports = workflow.getCurrentWorkflowReports();

  const { workflowsStore } = useStore();

  const getReportIndex = () => {
    return reports.findIndex((report) => report.reportUuid === reportUuid);
  };

  const [editable, setEditable] = useState(() => {
    return (
      // have we set an outputMapping yet?
      outputMappingUuid === null &&
      // are we in the output stage?
      editingStage === WORKFLOW_STAGES.OUTPUT &&
      // is this a new draft workflow?
      workflow.status === WORKFLOW_STATUS.DRAFT &&
      // has this output not been saved yet?
      saved === false
    );
  });

  const toggleSummaryView = (value) => {
    setSummaryView(value);
  };

  const deleteIcon = () => {
    if (workflow.getCurrentWorkflowReports().length === 1) {
      return styles.disabledDeleteCommand;
    } else {
      return styles.deleteCommand;
    }
  };

  const cloneOutput = async () => {
    const reports = workflow.getCurrentWorkflowReports();

    const clonedReport = { ...reports[getReportIndex()] };
    clonedReport.reportName = reports[getReportIndex()]["reportName"].concat(" (copy)");
    clonedReport.reportUuid = uuidv4();

    workflowsStore.addClonedReportToWorkflow(clonedReport);

    const response = await workflowsStore.saveWorkflowClonedOutput();
    const { success } = response;
    if (success) {
      reports.push(clonedReport);
    } else {
      workflowsStore.removeLastReportFromWorkflow();
      reports.pop();
    }
  };

  const deleteOutput = async () => {
    const reports = workflow.getCurrentWorkflowReports();
    //Remove any outputs which have no outputMappingUuid's assigned or haven't been saved, No BE request needed
    if (outputMappingUuid === null || saved === false) {
      reports.splice(
        reports.findIndex((report) => report.reportUuid === reportUuid),
        1
      );
    } else {
      const { success } = await workflowsStore.deleteWorkflowOutput(reportUuid);

      if (success) {
        reports.splice(
          reports.findIndex((report) => report.reportUuid === reportUuid),
          1
        );
      }
    }
  };

  const toggleRemoveReportOutputModal = () => {
    modalInstance(MODAL_IDS.CONFIRM_DELETE_REPORT_OUTPUT.concat(getReportIndex())).toggle();
  };

  const updateReport = (event) => {
    const {
      target: { value },
    } = event;

    workflow.updateReportName(value, getReportIndex());
  };

  const checkIfEditViewAndLastReport = () => {
    if (workflowsStore.getReadOnlyWorkflow() !== null) {
      if (editingStage !== WORKFLOW_STAGES.OUTPUT) {
        let reportIndex = getReportIndex();
        const reportsLength = workflowsStore.getReadOnlyWorkflowReports().length;

        return reportIndex + 1 === reportsLength ? "" : styles.outputSectionBottomBorder;
      } else {
        return styles.outputSectionBottomBorder;
      }
    }
  };

  return (
    <>
      <WarningConfirmationModal
        modalId={MODAL_IDS.CONFIRM_DELETE_REPORT_OUTPUT.concat(getReportIndex())}
        heading={`"Are you sure you want to delete output '${reportName}'?"`}
        text="Deletion will result in the removal of any associations the output has with workflows, templates or output mappings. This action cannot be undone."
        cancel="Cancel"
        confirm="Delete output"
        onConfirm={() => {
          deleteOutput();
        }}
      />
      <div
        className={[
          `${getReportIndex() === 0 && editingStage === WORKFLOW_STAGES.OUTPUT ? styles.outputSectionTopBorder : `${checkIfEditViewAndLastReport()}`}`,
        ].join(" ")}
      >
        <div className={["d-flex align-items-center", `${!summaryView ? "pt-12 pb-12" : "pt-12"}`].join(" ")}>
          {editable || summaryView ? (
            <IconDownArrow
              className={[`${styles.iconDownArrow}`, `${editable && styles.iconDownArrowDisabled}`].join(" ")}
              onClick={() => {
                toggleSummaryView(!summaryView);
              }}
            />
          ) : (
            <IconRightArrow
              className={styles.iconRightArrow}
              onClick={() => {
                toggleSummaryView(!summaryView);
              }}
            />
          )}
          <div className={[`${styles.iconSpaceLeft}`, "flex-grow-1"].join(" ")}>
            <C8>
              {editable && (
                <Input
                  onChange={(event) => {
                    updateReport(event);
                  }}
                  placeholderText={reportName}
                  size="md"
                  value={reportName}
                />
              )}

              {!editable && (
                <Text variant="secondary" size="sm" weight="medium">
                  {reportName === null ? "Output_".concat(getReportIndex() + 1) : reportName}
                </Text>
              )}
            </C8>
          </div>
          <div className="d-flex ">
            {editingStage === WORKFLOW_STAGES.OUTPUT && (
              <>
                <IconPencil
                  className={[`${styles.editCommand}`, `${editable && styles.disabledEditCommand}`].join(" ")}
                  onClick={() => {
                    setEditable(true);
                    workflowsStore.addEditableOutput(reportUuid);
                  }}
                />
                <IconClone
                  className={[`${styles.cloneCommand}`, `${editable && styles.disabledCloneCommand}`].join(" ")}
                  onClick={() => {
                    cloneOutput();
                  }}
                />
                <IconDelete
                  className={[`${deleteIcon()}`].join(" ")}
                  onClick={() => {
                    toggleRemoveReportOutputModal();
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className={[`${summaryView && "pb-12"}`].join(" ")}>
          {summaryView !== false && !editable && <OutputSectionSummaryView report={report} workflow={workflow} getReportIndex={getReportIndex()} />}
        </div>
        <div>{editable && React.cloneElement(children, { editable: editable, setEditable: setEditable })}</div>
      </div>
    </>
  );
});

export { OutputSection };
