import { useEffect, useContext } from "react";

import { FE_SOURCE_TYPES } from "utils/enums";
import { ArrayOptionDropdown } from "../../DropdownOptions/ArrayOptionDropdown";
import { WorkflowConfigurationContext } from "../../ConfigureWorkflow";

const InputSourceView = ({
  option,
  emailData,
  sftpData,
  selectedImportEmail,
  setSelectedImportEmail,
  selectedImportSftpConnection,
  setSelectedImportSftpConnection,
  selectedSftpFolder,
  setSelectedSftpFolder,
  workflowSource,
  setWorkflowSource,
  sourceWorkflowsData,
}) => {
  const { workflow } = useContext(WorkflowConfigurationContext);

  //Setting a default value for connectionUuid because it won't be present if we have no source yet.
  const { connectionUuid = "", inputMappingId = "", workflowUuid = "", type } = option;

  const workflowSources = sourceWorkflowsData.filter((source) => source.uuid !== workflow.uuid);

  useEffect(() => {
    if (sftpData.length > 0 && type === FE_SOURCE_TYPES.SFTP && !Object.hasOwn(selectedImportSftpConnection, "connectionUuid")) {
      setSelectedImportSftpConnection({ ...getSelectedSource(sftpData, connectionUuid) });
      setSelectedSftpFolder(getSelectedInputMapping(getSelectedSource(sftpData, connectionUuid), inputMappingId));
    }

    if (emailData.length > 0 && type === FE_SOURCE_TYPES.EMAIL && !Object.hasOwn(selectedImportEmail, "connectionUuid")) {
      setSelectedImportEmail({ ...getSelectedSource(emailData, connectionUuid) });
    }

    if (workflowSources.length > 0 && type === FE_SOURCE_TYPES.WORKFLOW) {
      setWorkflowSource({ ...getSelectedWorkflow(workflowSources, workflowUuid) });
    }
  }, [emailData, sftpData, type]);

  const updateImportSftpConnection = (index, value) => {
    setSelectedImportSftpConnection({ ...{ index }, ...value });
    setSelectedSftpFolder(getSelectedInputMapping({ ...{ index }, ...value }, inputMappingId));
  };

  const updateImportEmailAddress = (index, value) => {
    setSelectedImportEmail({ ...{ index }, ...value });
  };

  const updateSftpFolder = (index, value) => {
    setSelectedSftpFolder({ ...{ index }, ...value });
  };

  /**
   * This will assemble the selected object for email/sftp if a source exists already.
   * If none exist it will set a default.
   *
   * This method returns an object.
   *
   * @param data is an array of objects
   */
  const getSelectedSource = (data, connectionUuid) => {
    /**
     * Find matching connection and index so we can set this as selected in dropdown.
     * If we have no matches we will get an undefined.
     */
    const sourceConnection = data.find((connection) => connection.connectionUuid === connectionUuid);
    const sourceConnectionIndex = data.findIndex((connection) => connection.connectionUuid === connectionUuid);
    /**
     * Check if source connection is undefined.
     * if it is set the default. We should have a default if we are at this point because we checked for sftpData length.
     * Otherwise if it's not undefined then construct the existing connection.
     * **/
    const selectedConnection = sourceConnection === undefined ? { index: 0, ...data[0] } : { index: sourceConnectionIndex, ...sourceConnection };
    return selectedConnection;
  };

  const getSelectedInputMapping = (selectedImportSftpConnection, inputMappingId) => {
    const inputMapping = selectedImportSftpConnection.inputMappings.find((mapping) => mapping.id === inputMappingId);
    const inputMappingIndex = selectedImportSftpConnection.inputMappings.findIndex((mapping) => mapping.id === inputMappingId);

    //Set defaultInputMapping as selected if the inputMappings array has any objects otherwise set empty object
    const defaultInputMapping = selectedImportSftpConnection.inputMappings.length > 0 ? selectedImportSftpConnection.inputMappings[0] : {};
    const selectedInputMapping = inputMapping === undefined ? defaultInputMapping : { index: inputMappingIndex, ...inputMapping };
    return selectedInputMapping;
  };

  const getSelectedWorkflow = (data, workflowUuid) => {
    /**
     * Find matching workflow and index so we can set this as selected in dropdown.
     * If we have no matches we will get an undefined.
     */
    const workflowSource = data.find((workflow) => workflow.uuid === workflowUuid);
    const workflowSourceIndex = data.findIndex((workflow) => workflow.uuid === workflowUuid);
    /**
     * Check if workflow is undefined if it is set the default for now.
     * **/
    const selectedWorkflow = workflowSource === undefined ? { index: 0, ...data[0] } : { index: workflowSourceIndex, ...workflowSource };

    return selectedWorkflow;
  };

  const updateWorkflowSource = (index, value) => {
    setWorkflowSource({ index, ...value });
  };

  return (
    <>
      {FE_SOURCE_TYPES.WORKFLOW === type && (
        <ArrayOptionDropdown label="Choose workflow" options={workflowSources} option={workflowSource} setOption={updateWorkflowSource} objKey="name" />
      )}
      {FE_SOURCE_TYPES.SFTP === type && (
        <>
          <ArrayOptionDropdown
            label="Choose SFTP connection"
            options={sftpData}
            option={selectedImportSftpConnection}
            setOption={updateImportSftpConnection}
            objKey="name"
          />
          <ArrayOptionDropdown
            label="Choose SFTP directory"
            options={selectedImportSftpConnection.inputMappings}
            disabled={selectedImportSftpConnection.inputMappings.length === 0}
            option={selectedSftpFolder}
            setOption={updateSftpFolder}
            objKey="name"
            objKey2="fileSource"
          />
        </>
      )}

      {FE_SOURCE_TYPES.EMAIL === type && (
        <>
          <ArrayOptionDropdown
            label="Choose email address"
            options={emailData}
            option={selectedImportEmail}
            setOption={updateImportEmailAddress}
            objKey2="emailAddress"
            objKey="name"
          />
        </>
      )}
    </>
  );
};

export { InputSourceView };
