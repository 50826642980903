import React from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
  IconDownArrow,
  Text,
  SelectedIcon,
} from "@fundrecs/ui-library";
import styles from "./ArrayOptionDropdown.module.scss";

/**
 * Component to display a multiple values in a dropdown with an associated label.
 * The Dropdown contains multiple options.
 * disabled is a boolean which enables or disabled the dropdown.
 * options is an array we will map over.
 * option is an object containing the active index and value.
 * setOption is a function used to update the active option in the parent.
 * objKey is the name of the field which is to be displayed when dropdown type is selected
 * objKey2 is the name of the field which is to be displayed after the objKey in the same dropdown
 */
const ArrayOptionDropdown = ({ label, disabled, options, option, setOption, objKey, objKey2, disabledOptions, icon }) => {
  const { index, [objKey]: firstName, [objKey2]: secondName } = option;
  return (
    <div className="mb-24">
      <div className="mb-8 d-flex">
        <Text size="sm" weight="medium" variant="secondary">
          {label}
          {icon && icon}
        </Text>
      </div>
      <Dropdown>
        <DropdownButton size="md" disabled={disabled}>
          <DropdownButtonText>
            <ItemBody>
              {firstName}
              <span className="ml-8">
                <Text size="sm" weight="regular" variant="muted">
                  {secondName}
                </Text>
              </span>
            </ItemBody>
            <IconDownArrow className="btn-md-svg" />
          </DropdownButtonText>
        </DropdownButton>
        <DropdownList classes={styles.dropdownList}>
          {options.map((optionValue, optionIndex) => {
            const firstName = optionValue[objKey];
            const secondName = optionValue[objKey2];
            return (
              <DropdownListItem
                onClick={() => {
                  if (!disabledOptions(optionValue)) {
                    setOption(optionIndex, optionValue);
                  }
                }}
                key={optionIndex}
              >
                <DropdownItem active={index} index={optionIndex} classes={disabledOptions(optionValue) ? "disabled" : ""}>
                  {disabledOptions(optionValue) ? (
                    <ItemBody>
                      <Text size="sm" weight="regular" variant="muted">
                        {firstName} {secondName}
                      </Text>
                    </ItemBody>
                  ) : (
                    <ItemBody>
                      {firstName}
                      <span className="ml-8">
                        <Text size="sm" weight="regular" variant="link">
                          {secondName}
                        </Text>
                      </span>
                    </ItemBody>
                  )}

                  <SelectedIcon active={index} index={optionIndex} size="sm" />
                </DropdownItem>
              </DropdownListItem>
            );
          })}
        </DropdownList>
      </Dropdown>
    </div>
  );
};

//Proptypes for ArrayOptionDropdown
ArrayOptionDropdown.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  option: PropTypes.object,
  setOption: PropTypes.func,
  objKey: PropTypes.string,
  objKey2: PropTypes.string,
};

//Specifies the default values for props:
ArrayOptionDropdown.defaultProps = {
  label: "",
  disabled: false,
  options: [],
  option: {},
  setOption: () => {},
  objKey: "",
  objKey2: "",
  disabledOptions: () => {
    return false;
  },
};

export { ArrayOptionDropdown };
