import { useEffect, useContext } from "react";
import { Button, Text } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { DEFAULT_IMPORT_RULE_OBJECT, WORKFLOW_CONFIG_STAGE, WORKFLOW_STAGES } from "utils/workflows/enums";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";

import { WorkflowConfigurationContext } from "../../ConfigureWorkflow";
import { InputSection } from "./InputSection";
import { InputEditSection } from "./InputEditSection";

const InputEditView = observer(({ workflow, loadNextStageCreation }) => {
  const { workflowsStore } = useStore();

  const { editingStage, onStageComplete, fieldValues, setFieldValue, setWorkflowStage } = useContext(WorkflowConfigurationContext);

  const templateImportRules = workflow.getCurrentWorkflowTemplates();
  const importRuleUuid = uuidv4();
  useEffect(() => {
    if (templateImportRules.length === 0) {
      workflow.addNewCurrentWorkflowImportRule({
        ...DEFAULT_IMPORT_RULE_OBJECT,
        ...{ importRuleName: "Input_".concat(workflow.getCurrentWorkflowTemplates().length + 1), importRuleUuid: importRuleUuid },
      });
      workflowsStore.addEditableInput(importRuleUuid);
    }
  }, []);

  const checkIfSaveAndExitSetupDisabled = () => {
    if (workflowsStore.getReadOnlyWorkflow()) {
      return workflowsStore.getReadOnlyWorkflowTemplates().length === 0;
    } else {
      return true;
    }
  };

  return (
    <>
      {templateImportRules.map((importRule) => {
        return (
          <InputSection importRule={importRule} workflow={workflow} editingStage={editingStage}>
            <InputEditSection
              fieldValues={fieldValues}
              setFieldValue={setFieldValue}
              importRule={importRule}
              workflow={workflow}
              loadStageCreation={() => {
                setWorkflowStage(WORKFLOW_CONFIG_STAGE.INPUT);
              }}
            />
          </InputSection>
        );
      })}
      <div className="d-flex flex-row-reverse mb-24 mt-48">
        <Button
          size="md"
          color="primary"
          onClick={() => {
            workflowsStore.clearEditableInputs();
            onStageComplete(WORKFLOW_STAGES.INPUT);
            loadNextStageCreation();
          }}
          disabled={checkIfSaveAndExitSetupDisabled()}
        >
          <Text size="sm" weight="medium">
            Close step
          </Text>
        </Button>
      </div>
    </>
  );
});

export { InputEditView };
