import { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Text, Button, IconBolt, modalInstance, WarningConfirmationModal } from "@fundrecs/ui-library";

import { useStore, workflowsStore } from "../../../../../store/Store";
import { RULE_TYPE, FE_SOURCE_TYPES, BE_SOURCE_TYPES } from "../../../../../utils/enums";
import { MODAL_IDS } from "utils/workflows/enums";
import { InputSourceView } from "./InputSourceView";
import { useImportEmailConnectionData } from "components/workflows/hooks/useImportEmailConnectionData";
import { useImportSftpConnectionData } from "components/workflows/hooks/useImportSftpConnectionData";
import { useTeamId } from "store/hooks/useTeamId";
import { useSourceWorkflowsData } from "components/workflows/hooks/useSourceWorkflowsData";
import { ObjectOptionDropdown } from "../../DropdownOptions/ObjectOptionDropdown";
import { ActionButton } from "../../ActionButton/ActionButton";
import { TemplateSection } from "./template/TemplateRuleSection/TemplateSection";
import { AddInclusionRulePanel } from "./inclusionRule/AddInclusionRulePanel";
import { AddTemplatePanel } from "./template/panels/AddTemplatePanel";
import { ChangeTemplatePanel } from "./template/panels/ChangeTemplatePanel";
import { WorkflowConfigurationContext } from "../../ConfigureWorkflow";

const InputEditSection = observer(({ importRule, workflow, loadStageCreation, setEditable }) => {
  const { importRuleUuid, inclusionRules = [] } = importRule;

  const { setImportRuleIndex } = useContext(WorkflowConfigurationContext);

  const templateImportRules = workflow.getCurrentWorkflowTemplates();
  const { uuid } = workflow;
  const fieldValues = { templates: inclusionRules };
  const getImportRuleIndex = () => {
    return templateImportRules.findIndex((importRule) => importRule.importRuleUuid === importRuleUuid);
  };

  const defaultSource = { type: BE_SOURCE_TYPES.FUSION_UI };

  const checkTemplateImportRulesExist = () => {
    const { source = defaultSource } = importRule;
    return source;
  };

  const source = checkTemplateImportRulesExist() ?? defaultSource;
  const { type } = source;

  const getSourceIndex = (type) => {
    return Object.values(FE_SOURCE_TYPES).indexOf(type);
  };

  const [selectedImportEmail, setSelectedImportEmail] = useState({});
  const [selectedImportSftpConnection, setSelectedImportSftpConnection] = useState({ inputMappings: [] });
  const [selectedSftpFolder, setSelectedSftpFolder] = useState({});
  const [workflowSource, setWorkflowSource] = useState({ index: 0, name: "" });

  const sftpData = useImportSftpConnectionData();
  const emailData = useImportEmailConnectionData();
  const sourceWorkflowsData = useSourceWorkflowsData(uuid);

  const teamId = useTeamId();
  const [templateToChange, updateTemplateToChange] = useState("");
  const { templatesStore } = useStore();

  //Stores the rule we are editing so that it update it's original data in the templates list
  const [editRule, setEditableRule] = useState({});

  //Keeps track of the rule we are editing within the templates list.
  const [editRuleIndex, setEditableRuleIndex] = useState(null);

  //This stores details about the template uuid which needs to be added to each rule object
  const [ruleDetails, addRuleDetails] = useState({});

  //This controls the render of the page, we only render once data is fecthed from the API
  const [render, doRender] = useState(false);

  //We store the template Uuid to remove here and remove the rules which are linked to this Uuid from the templates list.
  const [templateToRemove, setTemplateToRemove] = useState("");

  //OutputMappingUuid which is set in useFusion20WorkflowState
  const outputMappingUuids = workflowsStore.getReadOnlyWorkflowReportsOutputMappingUuids();

  /**
   * We store the template Uuid and index for rules we want to delete, we need the index so we can track the position within the templates list
   * as you could have multiple matching template uuids within here.
   */
  const [ruleToDelete, setRuleToDelete] = useState("");

  const handleRemoveTemplate = (uuid) => {
    workflow.removeTemplateImportRule(uuid, getImportRuleIndex());
    setTemplateToRemove("");
  };

  const handleChangeTemplate = (newTemplateUuid) => {
    workflow.changeTemplateImportRule(newTemplateUuid, templateToChange, getImportRuleIndex());

    updateTemplateToChange("");
  };

  const checkActionButtonsDisabled = () => {
    return importRule.length > 0;
  };

  const addTemplateRule = (template) => {
    workflow.addTemplateImportRule(template, getImportRuleIndex());
  };

  const updateOption = (option) => {
    const { type } = option;
    workflow.updateTemplateImportRuleSourceType(type, getImportRuleIndex());
  };

  const loadTemplates = async () => {
    await templatesStore.loadTemplates(teamId);
    await loadTemplatesLinkedToOutputMappings();
  };

  const loadTemplatesLinkedToOutputMappings = async () => {
    const { success } = await templatesStore.loadTemplatesLinkedToOutputMappings(teamId, outputMappingUuids);
    doRender(success);
  };

  const selectExistingTemplates = () => {
    loadTemplatesLinkedToOutputMappings();
    modalInstance(MODAL_IDS.ADD_TEMPLATE.concat(importRuleUuid)).show();
  };

  useEffect(() => {
    if (teamId) {
      outputMappingUuids.length > 0 ? loadTemplates() : doRender(true);
    }
  }, [teamId]);

  //We need this because it turns the BE data into what the user should see on the UI.
  useEffect(() => {
    if (type === BE_SOURCE_TYPES.FUSION_UI) {
      updateOption({ index: 0, type: FE_SOURCE_TYPES.FUSION_UI });
    } else if (type === BE_SOURCE_TYPES.WORKFLOW) {
      updateOption({ index: 4, type: FE_SOURCE_TYPES.WORKFLOW });
    }
  }, []);

  const disableOptions = (value) => {
    if (value === FE_SOURCE_TYPES.SFTP) {
      return sftpData.length === 0;
    }

    if (value === FE_SOURCE_TYPES.EMAIL) {
      return emailData.length === 0;
    }
  };

  const getTemplatesWhichMatchUuid = (uuid) => {
    return inclusionRules.filter((template) => template.templateUuid === uuid);
  };

  const getTemplateIndex = (uuid) => {
    return inclusionRules.findIndex((template) => template.templateUuid === uuid);
  };

  const updateRules = (rules) => {
    //If we have an edit rule index we are editing an existing rule and we update the rules at this index within the array.
    if (editRuleIndex !== null) {
      rules.templateUuid = ruleDetails.templateUuid;
      inclusionRules[editRuleIndex] = { ...inclusionRules[editRuleIndex], ...rules };
    } else {
      //Check when we have 1 left as this could be MATCH_ALL, and we want to update this existing rule to something new replacing the MATCH_ALL object
      const template = getTemplatesWhichMatchUuid(ruleDetails.templateUuid);
      if (getTemplatesWhichMatchUuid(ruleDetails.templateUuid).length === 1 && template[0]["type"] === RULE_TYPE.MATCH_ALL) {
        rules.templateUuid = ruleDetails.templateUuid;
        let currentRules = inclusionRules;
        currentRules[getTemplateIndex(ruleDetails.templateUuid)] = rules;
      } else {
        //We are not editing an existing rule but adding a new one, So we update our existing rules with the new rule
        rules.templateUuid = ruleDetails.templateUuid;
        let currentRules = inclusionRules;
        currentRules.push(rules);
      }
    }
  };

  const removeRule = (index) => {
    const existingTemplates = inclusionRules;

    existingTemplates.splice(index, 1);
    setRuleToDelete("");
  };

  const cancelEditingInput = () => {
    if (templateImportRules.length === 1 && templateImportRules[getImportRuleIndex()]["saved"] === false) {
      //We only have one input we remove input
      templateImportRules.splice(getImportRuleIndex(), 1);
    } else if (templateImportRules[getImportRuleIndex()]["saved"] === false) {
      //This is a new input, we have more than 1 but this one hasn't been saved.
      templateImportRules.splice(
        templateImportRules.findIndex((importRule) => importRule.importRuleUuid === importRuleUuid),
        1
      );
      //We remove this input from the editable list
      workflowsStore.removeEditableInput(importRuleUuid);
    } else if (templateImportRules[getImportRuleIndex()]["saved"] === undefined) {
      //We need to find existing input in read only workflow and reset it when we cancel
      const existingImportRule = workflowsStore.getReadOnlyWorkflowTemplates().filter((importRule) => {
        return importRule.importRuleUuid === templateImportRules[getImportRuleIndex()]["importRuleUuid"];
      });

      if (existingImportRule.length > 0) {
        const [oldImportRule] = existingImportRule;
        templateImportRules[getImportRuleIndex()] = oldImportRule;
      }

      setEditable(false);

      //This is for outputs which have already been saved.
      workflowsStore.removeEditableInput(importRuleUuid);
    }
  };

  const createFromScratch = () => {
    setImportRuleIndex(getImportRuleIndex());
    loadStageCreation();
  };

  //Before completing the stage update the entire input object.
  const completeStage = () => {
    const updateInput = new Promise((resolve) => {
      const { type } = source;
      const { connectionUuid: sftpConnectionUuid } = selectedImportSftpConnection;
      const { id: sftpMappingId = "" } = selectedSftpFolder;

      const destructureEmailConnection = () => {
        const { connectionUuid: emailConnectionUuid } = selectedImportEmail;

        return { emailConnectionUuid: emailConnectionUuid };
      };

      const setSource = (type) => {
        if ([BE_SOURCE_TYPES.FUSION_UI, FE_SOURCE_TYPES.FUSION_UI].includes(type)) {
          return { type: BE_SOURCE_TYPES.FUSION_UI };
        } else if (type === FE_SOURCE_TYPES.FUSION_UI) {
          return { type: BE_SOURCE_TYPES.FUSION_UI };
        } else if (type === FE_SOURCE_TYPES.EMAIL) {
          return {
            type: type,
            connectionUuid: destructureEmailConnection()["emailConnectionUuid"],
          };
        } else if (type === FE_SOURCE_TYPES.SFTP) {
          return { type: type, connectionUuid: sftpConnectionUuid, inputMappingId: sftpMappingId };
        } else if ([FE_SOURCE_TYPES.WORKFLOW, BE_SOURCE_TYPES.WORKFLOW].includes(type)) {
          return {
            workflowUuid: workflowSource.uuid,
            type: BE_SOURCE_TYPES.WORKFLOW,
          };
        }

        // Default return type if no specific condition e.g. DTCC.
        return { type: type };
      };

      let currentWorkflowImportRule = (workflow.configuration.steps["APPLY_TEMPLATE_V1-1"].params.templateImportRules[getImportRuleIndex()] = {
        source: {
          ...setSource(type),
        },
        inclusionRules: [...inclusionRules],
        importRuleName: workflow.configuration.steps["APPLY_TEMPLATE_V1-1"].params.templateImportRules[getImportRuleIndex()]["importRuleName"],
        importRuleUuid: workflow.configuration.steps["APPLY_TEMPLATE_V1-1"].params.templateImportRules[getImportRuleIndex()]["importRuleUuid"],
      });

      resolve(workflowsStore.updateReadOnlyWorkflowInput(currentWorkflowImportRule, getImportRuleIndex()));
    });
    updateInput.then(() => {
      importRule.saved = true;
      setEditable(false);
      workflowsStore.removeEditableInput(importRuleUuid);
      workflowsStore.updateWorkflowInput(teamId, workflow.uuid, workflowsStore.getReadOnlyWorkflow());
    });
  };

  return (
    render && (
      <>
        <WarningConfirmationModal
          modalId={MODAL_IDS.CONFIRM_REMOVE_TEMPLATE.concat(importRuleUuid)}
          heading="Are you sure you want to remove this template?"
          text="This may affect your workflow. This template can still be accessed from Templates in your team's resources"
          cancel="Cancel"
          confirm="Remove template"
          onConfirm={() => {
            handleRemoveTemplate(templateToRemove);
          }}
        />
        <WarningConfirmationModal
          modalId={MODAL_IDS.CONFIRM_DELETE_IMPORT_RULE.concat(importRuleUuid)}
          heading="Are you sure you want to delete this import rule?"
          text="This may affect your workflow. This action cannot be undone."
          cancel="Cancel"
          confirm="Delete rule"
          onConfirm={() => {
            const { index, templateUuid } = ruleToDelete;
            removeRule(index, templateUuid);
          }}
        />
        <ChangeTemplatePanel
          fieldValues={fieldValues}
          templates={templatesStore.getTemplatesLinkedToOutputMapping()}
          templateToChange={templateToChange}
          updateTemplateToChange={updateTemplateToChange}
          handleChangeTemplate={handleChangeTemplate}
          importRuleUuid={importRuleUuid}
        />
        <AddTemplatePanel
          addTemplateRule={addTemplateRule}
          fieldValues={fieldValues}
          templates={templatesStore.getTemplatesLinkedToOutputMapping()}
          importRuleUuid={importRuleUuid}
        />
        <AddInclusionRulePanel
          conditionObject={editRule}
          setInclusionRules={updateRules}
          setEditableRuleIndex={setEditableRuleIndex}
          importRuleUuid={importRuleUuid}
        />
        <div className="pr-24 pl-24 pt-20 pb-20">
          <ObjectOptionDropdown
            label="Choose source type"
            options={FE_SOURCE_TYPES}
            option={{ index: getSourceIndex(type), ...source }}
            setOption={updateOption}
            disabledOptions={disableOptions}
            disabledText="None available"
            objKey="type"
          />
          <InputSourceView
            {...{
              option: { index: getSourceIndex(type), ...source },
              emailData,
              sftpData,
              selectedImportEmail,
              setSelectedImportEmail,
              selectedImportSftpConnection,
              setSelectedImportSftpConnection,
              selectedSftpFolder,
              setSelectedSftpFolder,
              workflowSource,
              setWorkflowSource,
              sourceWorkflowsData,
            }}
          />
          <div className="mb-8">
            <div className={[`${inclusionRules.length === 0 ? "pb-8" : "pb-20"}`].join(" ")}>
              <Text size="sm" weight="medium" variant="secondary">
                Assign template(s)
              </Text>
            </div>

            {[...new Set(inclusionRules.map((template) => template.templateUuid))].map((uuid, index) => {
              return (
                <span key={index}>
                  <TemplateSection
                    {...{
                      fieldValues,
                      uuid,
                      teamId,
                      addRuleDetails,
                      setEditableRule,
                      setEditableRuleIndex,
                      setRuleToDelete,
                      setTemplateToRemove,
                      updateTemplateToChange,
                      importRuleUuid,
                      workflow,
                      createFromScratch,
                    }}
                  />
                </span>
              );
            })}

            <div className={[`${inclusionRules.length > 0 && "pt-20"}`, "d-flex"].join(" ")}>
              <ActionButton
                title="Create template from scratch"
                subtitle="Create a new template to transform your input data"
                IconComponent={IconBolt}
                onClick={createFromScratch}
              />
              <ActionButton
                title="Select an existing template"
                subtitle="Choose a template from your team's resources"
                IconComponent={IconBolt}
                onClick={() => selectExistingTemplates()}
                disabled={templatesStore.getTemplatesLinkedToOutputMapping().length === 0}
              />
            </div>
          </div>
          <div className="mb-8">
            <Button
              size="md"
              color="primary"
              onClick={() => {
                completeStage();
              }}
              disabled={checkActionButtonsDisabled()}
            >
              <Text size="sm" weight="medium">
                Save input
              </Text>
            </Button>
            <span className="ml-12">
              <Button
                size="md"
                color="tertiary"
                onClick={() => {
                  cancelEditingInput();
                }}
                disabled={checkActionButtonsDisabled()}
              >
                <Text size="sm" weight="medium">
                  Cancel
                </Text>
              </Button>
            </span>
          </div>
        </div>
      </>
    )
  );
});

export { InputEditSection };
