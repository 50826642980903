import { useEffect, useState } from "react";
import { workflowsStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";

const useDestinationWorkflowsData = (workflowUuid) => {
  const teamId = useTeamId();

  const [destinationWorkflows, setDestinationWorkflows] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { data, success } = await workflowsStore.loadDestinationWorkflows(teamId, workflowUuid);
      if (success) {
        setDestinationWorkflows(data);
      }
    };

    fetchData();
  }, []);

  return destinationWorkflows;
};

export { useDestinationWorkflowsData };
