import { useContext, useLayoutEffect, useEffect, useState } from "react";
import {
  Text,
  modalInstance,
  Button,
  WarningConfirmationModal,
  ToolTipInformation,
  IconAdd,
  IconPencil,
  IconDelete,
  AlertConfirmationModal,
} from "@fundrecs/ui-library";
import { ActionButton } from "../../ActionButton/ActionButton";
import { ReactComponent as IconPage } from "../../icons/icon-page.svg";
import { ReactComponent as IconLibrary } from "../../icons/icon-library.svg";
import { isUserAuthorized } from "components/AuthorizationWrapper";
import { AUTHORITIES } from "utils/enums";
import { useStore } from "store/Store";
import { ObjectOptionDropdown } from "../../DropdownOptions/ObjectOptionDropdown";
import { ArrayOptionDropdown } from "../../DropdownOptions/ArrayOptionDropdown";
import {
  OUTPUT_DESTINATION_TYPES,
  DISABLED_OUTPUT_DESTINATION_TYPES,
  DISABLED_OUTPUT_FORMAT_TYPES,
  MODAL_IDS,
  OUTPUT_BE_TYPES,
  WORKFLOW_CONFIG_STAGE,
  OUTPUT_FORMAT_TYPES,
  HIDDEN_OUTPUT_FORMAT_TYPES,
  WORKFLOW_STATUS,
  OUTPUT_FILE_NAMING,
  OUTPUT_FILE_NAMING_TYPES,
  RULE_COMMAND_TYPES,
  RULE_COMMAND_ACTIONS,
  INCLUDE_COLUMNS_COMMAND_DISPLAY_COUNT,
} from "utils/workflows/enums";
import { OutputDestionationView } from "./OututDestinationView";
import { WorkflowConfigurationContext } from "../../ConfigureWorkflow.js";
import { AddOutputMappingPanel } from "./panels/AddOutputMappingPanel";
import { observer } from "mobx-react-lite";
import { ActionMenu } from "../../ActionMenu/ActionMenu";
import styles from "./OutputEditSection.module.scss";
import { fileNameExpressions } from "./customNaming/builderConfig";
import CustomNameBuilder from "./customNaming/CustomNameBuilder";
import { AddRuleToApplyPanel } from "./panels/AddRuleToApplyPanel";
import { fusionDataApi } from "api";

const OutputEditSection = observer(({ report, loadOutputMappings, sftpData, recData, emailData, lookupData, setEditable, destinationWorkflowsData }) => {
  const { rolesStore, outputMappingsStore, tenantConfigStore, workflowsStore } = useStore();

  const { setFieldValue, teamId, workflow, setWorkflowStage, setReportIndex } = useContext(WorkflowConfigurationContext);
  const sftpFolders = {};
  const recSides = {};

  const { reportUuid } = report;

  const getOutputDestinatonIndex = (type) => {
    return Object.values(OUTPUT_DESTINATION_TYPES)?.indexOf(OUTPUT_DESTINATION_TYPES[type]);
  };

  const getOutputFormatIndex = (type) => {
    return tenantConfigStore?.getOutputWriters()?.indexOf(type);
  };

  const reports = workflow.getCurrentWorkflowReports();

  const getReportIndex = () => {
    return reports?.findIndex((report) => report.reportUuid === reportUuid);
  };

  const getOutputNameType = (type) => {
    return reports?.length === 0 ? fileNamingReport?.fileNaming[0] : OUTPUT_FILE_NAMING?.filter((object) => object.type === type)[0];
  };

  const updateOutputNameType = (index) => {
    const outputName = OUTPUT_FILE_NAMING.filter((object) => object.index === index);
    const [fileNaming] = outputName;
    const { type } = fileNaming;
    workflow.updateFileNamingTypeForReport(type, getReportIndex());
  };

  const getFormatType = (destination, writer) => {
    if (destination === OUTPUT_DESTINATION_TYPES.LOOKUP) {
      return HIDDEN_OUTPUT_FORMAT_TYPES.RAW;
    } else if (destination === OUTPUT_DESTINATION_TYPES.RECONCILIATION) {
      return OUTPUT_FORMAT_TYPES.CSV;
    } else {
      return writer.type;
    }
  };

  const getOutputColumnName = (columnOrderNumber) => {
    if (columns?.length > 0 && columnOrderNumber) {
      const column = columns?.filter((col) => col?.columnOrderNumber === columnOrderNumber);
      const [{ columnName }] = column || {};
      return columnName;
    }
    return "";
  };

  const getIncludedColumnsNames = (indexArray) => {
    let columnNames = "";
    for (let i = 0; i < INCLUDE_COLUMNS_COMMAND_DISPLAY_COUNT; i++) {
      if (indexArray?.length > i) {
        columnNames += ` ${columns[indexArray[i]]?.columnName},`;
      }
    }
    return columnNames?.slice(0, -1); // Remove last comma from string
  };

  const { outputMappingUuid } = reports[getReportIndex()];
  const destination = reports[getReportIndex()] ?? { destinations: [{ type: OUTPUT_DESTINATION_TYPES.FUSION_UI }] };
  const format = reports[getReportIndex()] ?? { writer: { type: OUTPUT_FORMAT_TYPES.CSV } };
  const fileNamingReport = reports[getReportIndex()] ?? { fileNaming: [OUTPUT_FILE_NAMING[0]] };

  const { destinations } = destination;
  const [destinationObject] = destinations;
  const { type } = destinationObject;

  const {
    fileNaming: { type: fileNamingType },
  } = fileNamingReport;
  const { writer } = format;
  const formatType = getFormatType(destination, writer);

  const getOutputDestination = (type) => {
    switch (type) {
      case OUTPUT_BE_TYPES.LOOKUP:
        return OUTPUT_DESTINATION_TYPES.LOOKUP;
      case OUTPUT_BE_TYPES.RECONCILIATION:
        return OUTPUT_DESTINATION_TYPES.RECONCILIATION;
      case OUTPUT_BE_TYPES.FUSION_UI:
        return OUTPUT_DESTINATION_TYPES.FUSION_UI;
      case OUTPUT_BE_TYPES.SFTP:
        return OUTPUT_DESTINATION_TYPES.SFTP;
      case OUTPUT_BE_TYPES.WORKFLOW:
        return OUTPUT_DESTINATION_TYPES.WORKFLOW;
      default:
        return "";
    }
  };

  const [outputDestination, setOutputDestination] = useState({
    index: getOutputDestinatonIndex(type),
    ...{ ...destinationObject, ...{ type: getOutputDestination(type) } },
  });
  const [outputFormat, setOutputFormat] = useState({ index: getOutputFormatIndex(formatType), format: formatType });
  const [selectedExportEmail, setSelectedExportEmail] = useState({ index: 0, value: "" });
  const [selectedExportSftpConnection, setSelectedExportSftpConnection] = useState({ index: 0, name: "" });
  const [selectedSftpFolder, setSelectedSftpFolder] = useState({ index: 0, value: "" });
  const [selectedLookupTable, setSelectedLookupTable] = useState({ index: 0, name: "" });
  const [workflowDestination, setWorkflowDestination] = useState({ index: 0, name: "" });
  const [outputMappingName, setOutputMapingName] = useState("");
  const [newOutputMappingUuid, changeOutputMappingUuid] = useState(outputMappingUuid);

  const [outputNameType, setOutputNameType] = useState({ ...getOutputNameType(fileNamingType) });
  const outputNameTags = workflow.getFileNameTags(getReportIndex());

  const [selectedRecType, setSelectedRecType] = useState({ index: 0, recTypeName: "" });
  const [selectedRecSide, setSelectedRecSide] = useState({ index: 0, tmoName: "" });
  const [columns, setColumns] = useState([]);
  const [ruleAction, setRuleAction] = useState({ action: "Create", type: "" });

  const { status } = workflow;
  const outputWriters = tenantConfigStore.getOutputWriters();

  useLayoutEffect(() => {
    getOutputDestination(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputDestination]);

  useEffect(() => {
    setOutputFormat({ index: getOutputFormatIndex(formatType), format: formatType });
    const getOutputMappingName = () => {
      outputMappingsStore.getOutputMappingById({ teamId: teamId, id: outputMappingUuid }).then(({ data }) => {
        setOutputMapingName(data?.name);
      });
    };

    outputMappingUuid && getOutputMappingName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputMappingUuid]);

  useEffect(() => {
    const outputName = OUTPUT_FILE_NAMING.filter((object) => object.type === fileNamingType);
    setOutputNameType(outputName[0]);
  }, [fileNamingType]);

  useEffect(() => {
    if (teamId !== undefined && ![undefined, null].includes(outputMappingUuid)) {
      fusionDataApi.outputMappings.getOutputMappingsColumns({ teamId, outputMappingId: outputMappingUuid }).then((response) => {
        const { status } = response;
        if (status === 200) {
          const { data } = response;
          let sortedData = data.sort((columnA, columnB) => columnA.columnOrderNumber - columnB.columnOrderNumber);

          sortedData.forEach((column) => {
            const { columnOrderNumber } = column;
            column.index = columnOrderNumber - 1;
          });

          setColumns(sortedData);
        }
      });
    }
  }, [outputMappingUuid, teamId]);

  const setDestinations = (type) => {
    switch (type) {
      case OUTPUT_DESTINATION_TYPES.FUSION_UI:
      case "FUSION_UI":
        return {
          type: "FUSION_UI",
        };
      case OUTPUT_DESTINATION_TYPES.SFTP:
      case OUTPUT_BE_TYPES.SFTP:
        return {
          connectionUuid: selectedExportSftpConnection.connectionUuid,
          exportMappingId: selectedSftpFolder?.id,
          subfolderConfiguration: [],
          type: OUTPUT_BE_TYPES.SFTP,
        };
      case OUTPUT_DESTINATION_TYPES.WORKFLOW:
      case OUTPUT_BE_TYPES.WORKFLOW:
        return {
          workflowUuid: workflowDestination.uuid,
          type: OUTPUT_BE_TYPES.WORKFLOW,
        };

      case OUTPUT_DESTINATION_TYPES.EMAIL:
        return { connectionUuid: selectedExportEmail.connectionUuid, exportMappingId: 123, subfolderConfiguration: [], type: type };
      case OUTPUT_DESTINATION_TYPES.LOOKUP:
        return { connectionUuid: selectedLookupTable.uuid, type: type === OUTPUT_DESTINATION_TYPES.LOOKUP ? "LOOKUP" : type };
      case OUTPUT_DESTINATION_TYPES.RECONCILIATION:
        return {
          recTypeId: selectedRecType.recTypeId,
          recSideTmoUuid: selectedRecSide.tmoUuid,
          type: OUTPUT_DESTINATION_TYPES.RECONCILIATION ? "RECONCILIATION" : type,
        };
      default:
        return "";
    }
  };

  const handleRemoveOutputMapping = () => {
    changeOutputMappingUuid("");
    workflow.updateCurrentWorkflowReportOutputMapping(getReportIndex(), null);
  };

  const toggleRemoveOutputMappingModal = () => {
    modalInstance(MODAL_IDS.CONFIRM_DELETE_OUTPUT_MAPPING.concat(outputMappingUuid)).toggle();
  };

  const toggleChangeOutputMappingModal = () => {
    modalInstance(MODAL_IDS.CONFIRM_CHANGE_OUTPUT_MAPPING.concat(outputMappingUuid)).toggle();
  };

  const updateOutputDestination = (option) => {
    setOutputDestination(option);
  };

  const updateOutputFormat = (option) => {
    setOutputFormat(option);
  };

  const disableOptions = (value) => {
    if (value === OUTPUT_DESTINATION_TYPES.SFTP) {
      return sftpData.length === 0;
    }

    if (value === OUTPUT_DESTINATION_TYPES.EMAIL) {
      return emailData.length === 0;
    }

    if (value === OUTPUT_DESTINATION_TYPES.LOOKUP) {
      return lookupData.length === 0;
    }

    if (Object.values(DISABLED_OUTPUT_DESTINATION_TYPES).includes(value)) {
      return true;
    }

    if (Object.values(DISABLED_OUTPUT_FORMAT_TYPES).includes(value)) {
      return true;
    }

    if (value.type === OUTPUT_FILE_NAMING_TYPES.ORIGINAL) {
      return true;
    }
  };

  const setFileNaming = (outputNameType, outputNameTags) => {
    const { type } = outputNameType;
    if (type === OUTPUT_FILE_NAMING_TYPES.AUTO) {
      return {
        type: "AUTO",
      };
    } else if (type === OUTPUT_FILE_NAMING_TYPES.CUSTOM) {
      return {
        type: "CUSTOM",
        fileName: outputNameTags,
      };
    } else if (type === OUTPUT_FILE_NAMING_TYPES.ORIGINAL) {
      return {
        type: "ORIGINAL",
      };
    }
  };

  const selectExistingOutputMappings = () => {
    //Reset the state if outputMappingUuid is still null
    if (outputMappingUuid === null) {
      changeOutputMappingUuid("");
    }
    loadOutputMappings();
    modalInstance(MODAL_IDS.ADD_OUTPUT_MAPPING.concat(getReportIndex())).show();
  };

  const addOutputMappingUuid = (uuid) => {
    workflow.updateCurrentWorkflowReportOutputMapping(getReportIndex(), uuid);
  };

  const cancelEditingOutput = () => {
    if (reports?.length === 1 && reports[getReportIndex()]["saved"] === false) {
      //We only have one output we reset output mapping to null when we hit cancel
      reports[getReportIndex()]["outputMappingUuid"] = null;
    } else if (reports[getReportIndex()]["saved"] === false) {
      //This is a new output, we have more than 1 but this one hasn't been saved.
      reports?.splice(
        reports?.findIndex((report) => report?.reportUuid === reportUuid),
        1
      );
      //We remove this output from the editable list
      workflowsStore.removeEditableOutput(reportUuid);
    } else if (reports[getReportIndex()]["saved"] === undefined) {
      //We need to find existing report in read only workflow and reset it when we cancel
      const existingReport = workflowsStore.getReadOnlyWorkflowReports()?.filter((report) => report?.reportUuid === reports[getReportIndex()]["reportUuid"]);

      if (existingReport?.length > 0) {
        const [oldReport] = existingReport;
        reports[getReportIndex()] = oldReport;
      }

      setEditable(false);

      //This is for outputs which have already been saved.
      workflowsStore.removeEditableOutput(reportUuid);
    }
  };

  /**
   * Check what the destination type is.
   * If it's reconciliation then we need to use the tmoUuid as the
   * outputMappingUuid.
   * Otherwise use the outputMappingUuid.
   */
  const setOutputMapingUuid = (type) => {
    return type === OUTPUT_DESTINATION_TYPES.RECONCILIATION ? selectedRecSide?.tmoUuid : outputMappingUuid;
  };

  /**
   * When we clone an output sometimes the writer & fileNaming can be incorrect because this isn't needed for lookups or recs
   * so we need to set the default state for these.
   * @param {*} type
   * @returns
   */
  const writerFileNaming = (type) => {
    const { format } = outputFormat;

    let updatedFormat = {};

    if (type === OUTPUT_DESTINATION_TYPES.LOOKUP) {
      updatedFormat.type = "RAW";
    } else {
      updatedFormat.type = format;
    }

    if ([OUTPUT_DESTINATION_TYPES.RECONCILIATION, OUTPUT_BE_TYPES.RECONCILIATION].includes(type)) {
      return { fileNaming: { type: OUTPUT_FILE_NAMING_TYPES.AUTO }, writer: { type: OUTPUT_FORMAT_TYPES.CSV } };
    } else if ([OUTPUT_DESTINATION_TYPES.LOOKUP, OUTPUT_BE_TYPES.LOOKUP].includes(type)) {
      return { fileNaming: { type: OUTPUT_FILE_NAMING_TYPES.AUTO }, writer: { type: HIDDEN_OUTPUT_FORMAT_TYPES.RAW } };
    } else {
      return { writer: updatedFormat, fileNaming: setFileNaming(outputNameType, outputNameTags) };
    }
  };

  const saveOutput = () => {
    const updateReport = new Promise((resolve) => {
      const { type } = outputDestination;

      const currentWorkflowReport = (workflow.configuration.steps["DELIVER_FILES_V1-1"].params.reports[getReportIndex()] = {
        ...writerFileNaming(type),
        outputMappingUuid: setOutputMapingUuid(type),
        destinations: [setDestinations(type)],
        reportUuid: workflow.configuration.steps["DELIVER_FILES_V1-1"].params.reports[getReportIndex()]["reportUuid"],
        reportName: workflow.configuration.steps["DELIVER_FILES_V1-1"].params.reports[getReportIndex()]["reportName"],
        commands: workflow.configuration.steps["DELIVER_FILES_V1-1"].params.reports[getReportIndex()]["commands"],
      });

      //It might make sense to update this from what's returned from the BE especially for RECS
      resolve(workflowsStore.updateReadOnlyWorkflowOutput(currentWorkflowReport, getReportIndex()));
    });

    updateReport.then(() => {
      report.saved = true;
      setEditable(false);
      workflowsStore.removeEditableOutput(reportUuid);
      workflowsStore.updateWorkflowOutput(teamId, workflow.uuid, workflowsStore.getReadOnlyWorkflow());
    });
  };

  const actionMenuItems = [
    {
      key: "VIEW",
      label: "View output mapping",
      disabled: true,
    },
    {
      key: "CHANGE",
      label: "Change output mapping",
    },
    {
      key: "REMOVE",
      label: "Remove output mapping from workflow",
    },
  ];

  const onActionClick = (event) => {
    switch (event) {
      case "CHANGE":
        toggleChangeOutputMappingModal();
        break;
      case "REMOVE":
        status === WORKFLOW_STATUS.PUBLISHED && toggleRemoveOutputMappingModal();
        status === WORKFLOW_STATUS.DRAFT && handleRemoveOutputMapping();
        break;
      default:
    }
  };

  const checkActionButtonsDisabled = (outputDestination) => {
    const { type } = outputDestination;
    if (type === OUTPUT_DESTINATION_TYPES.RECONCILIATION || type === OUTPUT_DESTINATION_TYPES.LOOKUP) {
      return false;
    } else if (reports[getReportIndex()]["outputMappingUuid"] === null) {
      return true;
    } else {
      return false;
    }
  };

  const applyRule = (rule) => {
    workflow.addRuleToCommands({ reportIndex: getReportIndex(), rule });
    modalInstance(MODAL_IDS.RULE_TO_APPLY.concat(getReportIndex())).hide();
  };

  return (
    <div className={styles.outputContainer}>
      <WarningConfirmationModal
        modalId={MODAL_IDS.CONFIRM_DELETE_OUTPUT_MAPPING.concat(outputMappingUuid)}
        heading={`"Are you sure you want to delete output mapping '${outputMappingName}'?"`}
        text="You will need to assign any new output mappings to the templates in step 2. This action cannot be undone."
        cancel="Cancel"
        confirm="Delete output mapping"
        onConfirm={() => {
          status === WORKFLOW_STATUS.PUBLISHED && handleRemoveOutputMapping();
        }}
      />
      <AlertConfirmationModal
        modalId={MODAL_IDS.CONFIRM_CHANGE_OUTPUT_MAPPING.concat(outputMappingUuid)}
        heading="Are you sure you want to change this output mapping?"
        text="Changing this output mapping will result in the removal of any associations the output mapping has with output rules, custom output name tags or templates."
        cancel="Cancel"
        confirm="Proceed"
        onConfirm={selectExistingOutputMappings}
      />
      <AddOutputMappingPanel
        setFieldValue={setFieldValue}
        report={report}
        workflow={workflow}
        sectionIndex={getReportIndex()}
        addOutputMappingUuid={addOutputMappingUuid}
        reports={reports}
        newOutputMappingUuid={newOutputMappingUuid}
      />

      <AddRuleToApplyPanel {...{ newOutputMappingUuid, sectionIndex: getReportIndex(), report, teamId, workflow, applyRule, columns, ruleAction }} />

      <ObjectOptionDropdown
        label="Choose destination type"
        options={OUTPUT_DESTINATION_TYPES}
        option={outputDestination}
        setOption={updateOutputDestination}
        disabledOptions={disableOptions}
        disabledText="None available"
        objKey="type"
      />
      <OutputDestionationView
        {...{
          sectionIndex: getReportIndex(),
          emailData,
          sftpData,
          recData,
          sftpFolders,
          lookupData,
          outputDestination,
          selectedExportEmail,
          setSelectedExportEmail,
          selectedExportSftpConnection,
          setSelectedExportSftpConnection,
          selectedSftpFolder,
          setSelectedSftpFolder,
          selectedLookupTable,
          setSelectedLookupTable,
          selectedRecType,
          setSelectedRecType,
          selectedRecSide,
          setSelectedRecSide,
          recSides,
          workflowDestination,
          setWorkflowDestination,
          destinationWorkflowsData,
        }}
      />

      {![OUTPUT_DESTINATION_TYPES.LOOKUP, OUTPUT_DESTINATION_TYPES.RECONCILIATION].includes(outputDestination.type) && (
        <>
          <ObjectOptionDropdown
            label="Choose output format"
            options={outputWriters}
            option={outputFormat}
            setOption={updateOutputFormat}
            disabledOptions={disableOptions}
            disabledText="None available"
            objKey="format"
          />
          <div className="mb-20">
            <Text size="sm" weight="medium" variant="secondary">
              Choose output mapping
            </Text>
          </div>
          <div className={[`${reports[getReportIndex()]["outputMappingUuid"] !== null && styles.outputSectionBottomBorder}`, "d-flex mb-20"].join(" ")}>
            {reports[getReportIndex()]["outputMappingUuid"] !== null ? (
              <>
                <div className="flex-grow-1">
                  <Text>{outputMappingName}</Text>
                </div>
                <ActionMenu items={actionMenuItems} onItemClick={onActionClick} />
              </>
            ) : (
              <>
                <ActionButton
                  title="Create from scratch"
                  subtitle="Create a new output mapping with columns"
                  IconComponent={IconPage}
                  onClick={() => {
                    setReportIndex(getReportIndex());
                    setWorkflowStage(WORKFLOW_CONFIG_STAGE.OUTPUT);
                  }}
                  disabled={!isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.WORKFLOW_EDIT]) })}
                />
                <ActionButton
                  title="Use an existing output mapping"
                  subtitle="Choose mapping from your team's library"
                  IconComponent={IconLibrary}
                  onClick={() => selectExistingOutputMappings()}
                  disabled={outputMappingsStore.getOutputMappings().length === 0}
                />
              </>
            )}
          </div>
          <div className="mb-8">
            <div className="d-flex">
              <div className="me-auto">
                <span className="mr-8">
                  <Text size="sm" weight="medium" variant="secondary">
                    Apply rule(s) to output - optional
                  </Text>
                </span>
                <ToolTipInformation
                  text="Output rules are applied to a transformed output post template(s)
application for further transformation, such as splitting into
multiple outputs, prior to the workflow generating the final
output(s) and sending onwards to the specified destination."
                  direction="top"
                />
              </div>
              <div>
                <Button
                  size="sm"
                  color="primary-secondary"
                  onClick={() => {
                    setRuleAction({ action: RULE_COMMAND_ACTIONS.CREATE, type: "" });
                    modalInstance(MODAL_IDS.RULE_TO_APPLY.concat(getReportIndex())).show();
                  }}
                  disabled={checkActionButtonsDisabled(outputDestination)}
                >
                  <IconAdd className="btn-md-svg" />
                  <Text size="xs" weight="medium">
                    New rule
                  </Text>
                </Button>
              </div>
            </div>

            <div className="pt-12">
              <>
                {workflow.doesRuleExist(getReportIndex(), RULE_COMMAND_TYPES.GroupByCommand) && (
                  <>
                    <div className="d-flex mb-8 pt-12">
                      <Text size="sm" weight="medium" variant="secondary">
                        Split output based on column
                      </Text>
                      <div className={["ms-auto pl-8", `${styles.noFlex}`].join(" ")}>
                        <IconPencil
                          className={[" btn-md-svg mr-12", `${styles.splitIconPointer}`].join(" ")}
                          onClick={() => {
                            setRuleAction({ action: RULE_COMMAND_ACTIONS.EDIT, type: RULE_COMMAND_TYPES.GroupByCommand });
                            modalInstance(MODAL_IDS.RULE_TO_APPLY.concat(getReportIndex())).show();
                          }}
                        />
                        <IconDelete
                          className={[" btn-md-svg", `${styles.splitIconPointer}`].join(" ")}
                          onClick={() => {
                            workflow.updateFileNamingTypeForReport(OUTPUT_FILE_NAMING_TYPES.AUTO, getReportIndex());
                            workflow.deleteCommand(getReportIndex(), RULE_COMMAND_TYPES.GroupByCommand);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex">
                        <Text element="div" size="xs" variant="tertiary" weight="regular" align="left">
                          Column to split output by:
                        </Text>
                        <span className="ml-4">
                          <Text element="div" size="xs" variant="primary" weight="medium" align="left">
                            {getOutputColumnName(workflow.getColumnOrderNumberForGroupByCommand(getReportIndex()))}
                          </Text>
                        </span>
                      </div>
                      <div className="d-flex">
                        <Text element="div" size="xs" variant="tertiary" weight="regular" align="left">
                          Include first cell of this column in output name:
                        </Text>
                        <span className="ml-4">
                          <Text element="div" size="xs" variant="primary" weight="medium" align="left">
                            {workflow.getIncludeFirstCellForGroupByCommand(getReportIndex()) ? "Yes" : "No"}
                          </Text>
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {workflow.doesRuleExist(getReportIndex(), RULE_COMMAND_TYPES.IncludeColumnsCommand) && (
                  <>
                    <div className="d-flex mb-8 pt-12">
                      <Text size="sm" weight="medium" variant="secondary">
                        Customise output columns
                      </Text>
                      <div className={["ms-auto pl-8", `${styles.noFlex}`].join(" ")}>
                        <IconPencil
                          className={[" btn-md-svg mr-12", `${styles.splitIconPointer}`].join(" ")}
                          onClick={() => {
                            setRuleAction({
                              action: RULE_COMMAND_ACTIONS.EDIT,
                              type: RULE_COMMAND_TYPES.IncludeColumnsCommand,
                              params: { includedColumnIndexArray: workflow.getColumnIndexArrayForIncludeColumnsCommand(getReportIndex()) },
                            });
                            modalInstance(MODAL_IDS.RULE_TO_APPLY.concat(getReportIndex())).show();
                          }}
                        />
                        <IconDelete
                          className={[" btn-md-svg", `${styles.splitIconPointer}`].join(" ")}
                          onClick={() => {
                            workflow.deleteCommand(getReportIndex(), RULE_COMMAND_TYPES.IncludeColumnsCommand);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <Text element="div" size="xs" variant="tertiary" weight="regular" align="left">
                        Columns to include in output:
                      </Text>
                      <div className="d-flex ml-4 ">
                        <Text element="div" size="xs" variant="primary" weight="medium" align="left">
                          {getIncludedColumnsNames(workflow.getColumnIndexArrayForIncludeColumnsCommand(getReportIndex()))}
                        </Text>
                        {workflow.getColumnsBadgeCount(getReportIndex()) > 0 && (
                          <span className={styles.badgeCounter}>+{workflow.getColumnsBadgeCount(getReportIndex())}</span>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {workflow.getCommands(getReportIndex())?.length === 0 && (
                  <div className="d-flex mb-8 pt-12">
                    <Text size="sm" weight="regular" variant="primary">
                      No rules applied to output
                    </Text>
                  </div>
                )}
              </>
            </div>
            <div className={styles.outputSectionBottomBorder} />
          </div>
          <div className="mb-8 mt-12">
            <Text size="sm" weight="medium" variant="secondary">
              Choose output name
            </Text>
          </div>
          <ArrayOptionDropdown
            options={OUTPUT_FILE_NAMING}
            option={outputNameType}
            setOption={updateOutputNameType}
            objKey={"label"}
            disabledOptions={disableOptions}
            disabled={workflow.isFirstCellEnabled(getReportIndex())}
          />
          {outputNameType.type === OUTPUT_FILE_NAMING_TYPES.CUSTOM && (
            <CustomNameBuilder
              expressions={outputNameTags}
              tags={fileNameExpressions}
              workflow={workflow}
              reportIndex={getReportIndex()}
              columns={columns}
              format={".".concat(outputFormat.format.toLowerCase())}
              aboveTagsText="Name example:"
              preview={true}
              belowTagsText="the below tags here to create your custom output file name"
              onChange={(action, value) => {
                if (action === "ADD") {
                  if (value !== undefined) {
                    workflow.addFileNameTags(getReportIndex(), value);
                  }
                } else if (action === "REMOVE") {
                  workflow.removeFileNameTags(getReportIndex());
                }
              }}
            />
          )}
        </>
      )}

      <div className="mb-8">
        <Button
          size="md"
          color="primary"
          onClick={() => {
            saveOutput();
          }}
          disabled={checkActionButtonsDisabled(outputDestination)}
        >
          <Text size="sm" weight="medium">
            Save output
          </Text>
        </Button>
        <span className="ml-12">
          <Button
            size="md"
            color="tertiary"
            onClick={() => {
              cancelEditingOutput();
            }}
            disabled={checkActionButtonsDisabled(outputDestination)}
          >
            <Text size="sm" weight="medium">
              Cancel
            </Text>
          </Button>
        </span>
      </div>
    </div>
  );
});

export { OutputEditSection };
