import { useState, useEffect } from "react";
import _ from "lodash";
import { fusionDataApi } from "api";
import { Text } from "@fundrecs/ui-library";
import { SummarySetting, SummarySettingsList } from "../../SettingsList/SummarySettingsList";
import {
  OUTPUT_DESTINATION_TYPES,
  OUTPUT_FORMAT_TYPES,
  OUTPUT_FILE_NAMING_TYPES,
  OUTPUT_FILE_NAMING,
  OUTPUT_BE_TYPES,
  RULE_COMMAND_TYPES,
  INCLUDE_COLUMNS_COMMAND_DISPLAY_COUNT,
} from "utils/workflows/enums";
import {
  getFormatType,
  getExportConnectionName,
  getExportMappingName,
  getDestinationRecSideUuid,
  getReconciliationTypeName,
  getReconciliationSideName,
  getExportMappingDiretoryPath,
} from "components/workflows/reusable";
import { useExportEmailConnectionData } from "components/workflows/hooks/useExportEmailConnectionData";
import { useExportSftpConnectionData } from "components/workflows/hooks/useExportSftpConnectionData";
import { useExportReconciliationData } from "components/workflows/hooks/useExportReconciliationData";
import { useStore } from "../../../../../store/Store";
import Preview from "./customNaming/Preview";
import { useExportLookupTableData } from "components/workflows/hooks/useExportLookupTableData";
import { useTeamId } from "store/hooks/useTeamId";
import styles from "./OutputSectionSummary.module.scss";

const OutputSectionSummaryView = ({ workflow, report, getReportIndex }) => {
  const exportSftpConnectionData = useExportSftpConnectionData();
  const exportEmailConnectionData = useExportEmailConnectionData();
  const { outputMappingsStore, workflowsStore } = useStore();
  const [outputMappingName, setOutputMapingName] = useState("");
  const [columns, setColumns] = useState([]);
  const teamId = useTeamId();
  const { reportUuid } = report;
  const exportRecData = useExportReconciliationData(teamId);

  const lookupData = useExportLookupTableData();

  const {
    outputMappingUuid = "",
    destinations: {
      0: { recTypeId = "", workflowUuid = null },
    },
  } = report || {};

  useEffect(() => {
    report && getOutputMappingName();
  }, [outputMappingUuid]);

  useEffect(() => {
    if (teamId !== undefined && ![undefined, null].includes(outputMappingUuid)) {
      fusionDataApi.outputMappings.getOutputMappingsColumns({ teamId, outputMappingId: outputMappingUuid }).then((response) => {
        const { status } = response;
        if (status === 200) {
          const { data } = response;
          let sortedData = data?.sort((columnA, columnB) => columnA.columnOrderNumber - columnB.columnOrderNumber);

          sortedData?.forEach((column) => {
            const { columnOrderNumber } = column;
            column.index = columnOrderNumber - 1;
          });

          setColumns(sortedData);
        }
      });
    }
  }, [outputMappingUuid, teamId]);

  const checkType = (type) => {
    if (type === OUTPUT_DESTINATION_TYPES.FUSION_UI) {
      //We need this because what we display on the UI doesn't match the type that comes from the API
      return "View in Fusion only";
    } else {
      return type;
    }
  };

  const getOutputColumnName = (columnOrderNumber) => {
    if (columns?.length > 0 && columnOrderNumber) {
      const column = columns?.filter((col) => col?.columnOrderNumber === columnOrderNumber);
      const [{ columnName }] = column || {};
      return columnName;
    }
    return "";
  };

  const getIncludedColumnsNames = (indexArray) => {
    let columnNames = "";

    if (columns?.length > 0) {
      for (let i = 0; i < INCLUDE_COLUMNS_COMMAND_DISPLAY_COUNT; i++) {
        if (indexArray?.length > i) {
          columnNames += ` ${columns[indexArray[i]]?.columnName},`;
        }
      }
    }

    return columnNames?.slice(0, -1); // Remove last comma from string
  };

  const getDestinationName = () => {
    const {
      destinations: [{ type, connectionUuid, exportMappingId }],
    } = report;

    if (![OUTPUT_BE_TYPES.FUSION_UI, OUTPUT_DESTINATION_TYPES.FUSION_UI].includes(type)) {
      if (type === OUTPUT_DESTINATION_TYPES.SFTP || type === OUTPUT_BE_TYPES.SFTP) {
        return (
          <>
            <SummarySetting
              name="SFTP directory name"
              value={
                exportSftpConnectionData &&
                (getExportMappingName(exportSftpConnectionData, connectionUuid, exportMappingId) ?? "Export Directory appears to have been deleted.")
              }
            />
            <SummarySetting
              name="SFTP directory path"
              value={
                exportSftpConnectionData &&
                (getExportMappingDiretoryPath(exportSftpConnectionData, connectionUuid, exportMappingId) ?? "Export Directory appears to have been deleted.")
              }
            />
            <SummarySetting
              name="SFTP connection"
              value={
                exportSftpConnectionData &&
                (getExportConnectionName(exportSftpConnectionData, connectionUuid) ?? "Export Connection appears to have been deleted.")
              }
            />
          </>
        );
      }
      if ([OUTPUT_DESTINATION_TYPES.WORKFLOW, OUTPUT_BE_TYPES.WORKFLOW].includes(type)) {
        return (
          <>
            <SummarySetting name="Workflow" value={workflowsStore.getWorkflowName(workflowUuid)} />
          </>
        );
      }
      if (type === OUTPUT_BE_TYPES.EMAIL) {
        return <SummarySetting name="Destination type" value={getExportConnectionName(exportEmailConnectionData, connectionUuid)} />;
      }
      if (type === OUTPUT_BE_TYPES.RECONCILIATION) {
        return <SummarySetting name="Reconciliation type" value={getReconciliationTypeName(exportRecData, recTypeId)} />;
      }
    } else {
      return <SummarySetting name="Destination type" value={OUTPUT_DESTINATION_TYPES.FUSION_UI} />;
    }
  };

  const getFormatName = () => {
    const {
      writer: { type },
    } = report;

    if (type === "CSV") {
      return <SummarySetting name="Output format" value={OUTPUT_FORMAT_TYPES.CSV} />;
    }
    if (type === "XLSX") {
      return <SummarySetting name="Output format" value={OUTPUT_FORMAT_TYPES.XLSX} />;
    }
  };

  const getOutputMappingName = async () => {
    // With LUMS and eventually Fusion Recs we won't have the outputMappingUuid in the frontend since it's created in the backend
    // We need to have it available for the summary output view and to prefill the next stage for input edit view
    if (outputMappingUuid) {
      outputMappingsStore.getOutputMappingById({ teamId: teamId, id: outputMappingUuid }).then(({ data }) => {
        setOutputMapingName(data.name);
      });
    }
  };

  const getOutputFileTypeObjectValue = (outputFileType) => {
    const outputTypeObject = OUTPUT_FILE_NAMING.filter((outputFileTypeObject) => {
      const { type } = outputFileTypeObject;
      return outputFileType === type;
    });

    return outputTypeObject[0]["label"];
  };

  const getOutputFileNameValue = (outputNameType) => {
    if (outputNameType === OUTPUT_FILE_NAMING_TYPES.AUTO) {
      return getOutputFileTypeObjectValue(OUTPUT_FILE_NAMING_TYPES.AUTO);
    } else if (outputNameType === OUTPUT_FILE_NAMING_TYPES.CUSTOM) {
      return (
        <div className="d-flex flex-column">
          <Preview expressions={outputFileName} format={".".concat(getFormatType(workflow).toLowerCase())} />
        </div>
      );
    }
  };

  const getLookupName = () => {
    const lookup = lookupData.filter((lookup) => {
      return lookup.outputMappingUuid === outputMappingUuid;
    });

    if (lookup.length > 0) {
      return lookup[0]["name"];
    } else {
      return "";
    }
  };

  //We currently assume we will be only getting the first object from the destinations array. In time we might have to deal with multiple
  const outputDestinationType = report ? checkType(report.destinations[0].type) : "View in Fusion only";
  const outputNameType = report && report["fileNaming"]["type"];
  const outputFileName = report && report["fileNaming"]["fileName"];
  const commands = report && report["commands"];

  const getDestinationType = () => {
    if (outputDestinationType === OUTPUT_BE_TYPES.LOOKUP) {
      return OUTPUT_DESTINATION_TYPES.LOOKUP;
    } else if (outputDestinationType === OUTPUT_BE_TYPES.RECONCILIATION) {
      return OUTPUT_DESTINATION_TYPES.RECONCILIATION;
    } else if (outputDestinationType === OUTPUT_BE_TYPES.SFTP) {
      return OUTPUT_DESTINATION_TYPES.SFTP;
    } else if (outputDestinationType === OUTPUT_BE_TYPES.WORKFLOW) {
      return OUTPUT_DESTINATION_TYPES.WORKFLOW;
    } else {
      return outputDestinationType;
    }
  };

  const getCommands = () => {
    if (commands?.length > 0) {
      return (
        <div className={["d-flex", "mb-4", `${styles.item}`].join(" ")}>
          <span className={[`${styles.labels}`, "mr-4"].join(" ")}>
            <Text element="dt" size="sm" variant="tertiary" weight="regular">
              {"Rule applied".concat(" : ")}
            </Text>
          </span>
          <div className="d-flex flex-column">
            {_.sortBy(commands, "name")?.map((command, index) => {
              const { name, params } = command;

              if (name === RULE_COMMAND_TYPES.GroupByCommand) {
                const { firstCell } = params;

                return (
                  <div key={index}>
                    <Text element="dd" size="sm" variant="secondary">
                      Split output based on column
                    </Text>
                    <Text element="dt" size="sm" variant="tertiary" weight="regular">
                      Column to split output by: {getOutputColumnName(workflow.getColumnOrderNumberForGroupByCommand(getReportIndex))}
                    </Text>
                    <Text element="dt" size="sm" variant="tertiary" weight="regular">
                      Include first cell of this column in output name: {firstCell ? "Yes" : "No"}
                    </Text>
                  </div>
                );
              }

              if (name === RULE_COMMAND_TYPES.IncludeColumnsCommand) {
                return (
                  <div key={index}>
                    <Text element="dd" size="sm" variant="secondary">
                      Customise output columns
                    </Text>

                    <div className="d-flex">
                      <Text element="dt" size="sm" variant="tertiary" weight="regular">
                        Columns to include in output: {getIncludedColumnsNames(workflow.getColumnIndexArrayForIncludeColumnsCommand(getReportIndex))}
                      </Text>
                      {workflow.getColumnsBadgeCount(getReportIndex) > 0 && (
                        <span className={styles.badgeCounter}>+{workflow.getColumnsBadgeCount(getReportIndex)}</span>
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <SummarySettingsList>
      {outputDestinationType !== OUTPUT_BE_TYPES.FUSION_UI && <SummarySetting name="Destination type" value={getDestinationType()} />}
      {report && getDestinationName()}

      {outputDestinationType !== OUTPUT_BE_TYPES.RECONCILIATION && outputDestinationType !== OUTPUT_BE_TYPES.LOOKUP && (
        <>
          {report && getFormatName()}
          <SummarySetting name="Output mapping" value={outputMappingName} />
          {report && getCommands()}
          <SummarySetting name="Output name" value={getOutputFileNameValue(outputNameType)} />
        </>
      )}

      {outputDestinationType === OUTPUT_BE_TYPES.LOOKUP && <SummarySetting name="Lookup" value={getLookupName()} />}

      {outputDestinationType === OUTPUT_BE_TYPES.RECONCILIATION && (
        <SummarySetting name="Side" value={getReconciliationSideName(exportRecData, getDestinationRecSideUuid(workflow, reportUuid))} />
      )}
    </SummarySettingsList>
  );
};

export { OutputSectionSummaryView };
